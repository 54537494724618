/**
 * @overview recent sale popup prestashop module script
 * @author WNext
 * @web    http://wnext.net.au
 * @copyright  2015-2022 WNext
 * @email  contact@wnext.net.au
 * @version v1.5.3
 */
import Noty from 'noty';
import Storage from './storage';
import {fetch} from 'whatwg-fetch';

const TIME_STAMP_KEY =  "recentSaleTimestamp",
    POPUP_COUNT_KEY = "popupCount";

const Main = () => {

    let configOptions = {};

    let moduleOptions = {
        popupDelay : 0,
        maxPopupPerVisitor : -1,
        rsModulePath : ""
    }

    function loadConfiguration(completed) {
        const configUrl = prestashop.urls.base_url + "?fc=module&module=recentsalepopup&controller=recentSale&ajax=1&config=1"
        fetch(configUrl, {
            method: "GET",
            cache: "no-cache",
            mode: "no-cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            }
        })
        .then(res => {
            if(res.ok) return res.json();
            else throw "Recent sales module cannot load its configuration. Please contact the developer for support.";
        })
        .then(config => {
            const plusDelay = config.animation_speed == "" 
                ? 2 
                : (config.animation_speed == "normal" 
                    ? 5 : 8 );

            const displayTimeSeconds =  (config.delay+plusDelay)*1000;

            configOptions = {
                timeOffset : config.offset * 60,
                layout: config.position,
                timeout: displayTimeSeconds,
                animation: {
                    open: `animated ${config.animation_speed} ${config.animation_in}`,
                    close: `animated ${config.animation_speed} ${config.animation_out}`,
                    easing: 'swing',
                    speed: displayTimeSeconds
                },
                type: config.box_style,
                refreshInterval : (config.refresh_interval <= 0 ? 20 : config.refresh_interval) * 1000,
                serverTimezoneOffsetMinute: config.timezone_offset
            }

            moduleOptions.popupDelay = config.popupDelay;
            moduleOptions.maxPopupPerVisitor = config.maxPopupPerVisitor;
            moduleOptions.rsModulePath = config.rs_controller_path;
            completed();
        })
    }

    let recentSaleProductIndex = 0;
    let recentSaleProducts = [];
    //$.removeCookie("recentSaleTimestamp",{ path: '/' }); // uncomment for demo site

    // noty options
    const defaultOptions = {
        layout: 'bottomLeft',
        theme: 'relax',
        animation: {
            open: 'animated bounceInLeft', // Animate.css class names
            close: 'animated bounceOutLeft', // Animate.css class names
            easing: 'swing',
            speed: 5000
        },
        //timeout: 5000,
        maxVisible: 1,
        callbacks: {
            afterClose: function() {
                // load the next one if still available
                if(recentSaleProducts!=null && recentSaleProductIndex < recentSaleProducts.length-1) {
                    recentSaleProductIndex++;
                    showItem(recentSaleProducts[recentSaleProductIndex]);
                }
                else {
                    // load new set of data using ajax
                    recentSaleProducts = [];
                    recentSaleProductIndex = 0;
                    loadAjax();
                }
            }
        }
    };

    function loadAjax() {
        const currentTimeStamp = toServerTimeZone((new Date()).getTime()/1000) - configOptions.timeOffset;
        const sinceTimestamp = getCookieTimestamp(currentTimeStamp);
        // load items

        let url = (moduleOptions.rsModulePath && moduleOptions.rsModulePath != "") 
            ? moduleOptions.rsModulePath 
            : prestashop.urls.base_url + "?fc=module&module=recentsalepopup&controller=recentSale";
        
        const postData =  {
            ajax: true,
            token: prestashop.static_token,
            timestamp: sinceTimestamp,
            utcOffset: -(new Date()).getTimezoneOffset()
        };

        const queryString = buildQueryString(postData);
        if(url.indexOf("?") === -1) {
            url = url+"?"+queryString;
        }
        else url = url+"&"+queryString;
        
        fetch(url, {
            method: "GET",
            cache: "no-cache",
            mode: "no-cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            }
        })
        .then(res => {
            if(res.ok) return res.json();
            else throw "Error";
        })
        .then(result => {
            if(typeof result === "string") {
                // error response
                console.log("error "+result);
                return;
            }

            const resultData = result.data;

            if(resultData && resultData.length > 0) {
                recentSaleProducts = resultData;
                showItem(recentSaleProducts[recentSaleProductIndex])
            }
            else {
                setTimeout(loadAjax, configOptions.refreshInterval);
            }
        })
        ;
    }

    function buildQueryString(data) {
        let formBody = [];
        for (const [key, value] of Object.entries(data)) {
          const encodedKey = encodeURIComponent(key);
          const encodedValue = encodeURIComponent(value);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        return formBody.join("&");
    }

    function toServerTimeZone(clientTime) {
        return clientTime + (configOptions.serverTimezoneOffsetMinute + (new Date()).getTimezoneOffset());
    }

    function getCookieTimestamp(sinceTimestamp) {
        const value = Storage.getValue(TIME_STAMP_KEY);
        if(value != null &&
            sinceTimestamp < value) {
            return value;
        }
        else {
            Storage.setValue(TIME_STAMP_KEY, sinceTimestamp);
            return sinceTimestamp;
        }
    }

    function setCookieLaterTimestamp(newTimestamp) {
        const value = Storage.getValue(TIME_STAMP_KEY);
        if(value != null && value < newTimestamp){
            Storage.setValue(TIME_STAMP_KEY, newTimestamp);
        }
    }

    function getCookiePopupCount() {
        const todayString = getTodayString();

        const countValue = Storage.getValue(POPUP_COUNT_KEY);
        if(!countValue || countValue.today != todayString) {
            setCookiePopupCount(0);
            return 0;
        }

        return countValue.count;
    }

    function getTodayString() {
        const today = new Date();
        return today.getFullYear()+"-"+today.getMonth()+"-"+today.getDate();
    }

    function setCookiePopupCount(popupCount) {
        const todayString = getTodayString();
        const countValue = {
            count : popupCount,
            today: todayString
        }

        Storage.setValue(POPUP_COUNT_KEY, countValue);
    }

    function showNoty(option) {
        const notty = new Noty(option);
        notty.show();
    }

    function showItem(item) {
        if(moduleOptions.maxPopupPerVisitor > 0 && getCookiePopupCount() >= moduleOptions.maxPopupPerVisitor) {
            //console.log("Max popup shown. Exit");
            return;
        }

        setTimeout(function() {
            setCookieLaterTimestamp(item.timestamp);
            setCookiePopupCount(getCookiePopupCount()+1);
            var notyOptions = {
                ...defaultOptions,
                ...configOptions,
                text : item.display
            };
            //notyOptions.theme = "relax";
            //console.log("showItem-notyOptions", notyOptions);
            showNoty(notyOptions);
        }, moduleOptions.popupDelay*1000);
    }

    return {
        start : () => {
            loadConfiguration(() => loadAjax());
        }
    }
};

export default Main;